// 3rd Party Libraries
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Reducers
import quoteReducer from "../reducers/quote";
import orderReducer from "../reducers/order";
import settingsReducer from "../reducers/settings";

import { getSettings, getMakes } from "../actions/settings";

const rootReducer = combineReducers({
  quote: quoteReducer,
  order: orderReducer,
  settings: settingsReducer,
});

const persistConfig = {
  key: "root",
  whitelist: ["settings"],
  storage,
};

const pReducer = persistReducer(persistConfig, rootReducer);
let middleware;

if (process.env.NODE_ENV === "production") {
  middleware = applyMiddleware(thunk);
} else {
  middleware = applyMiddleware(thunk, logger);
}
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

store.dispatch(getSettings());
store.dispatch(getMakes());

export { persistor, store };
