import React from "react";

export default function Header(props) {
    return (
        <header id="header">
            <div className="app-heading">
                <img id="primary-logo" alt="JK Moving Logo" src="https://res.cloudinary.com/dq27r8cov/image/upload/v1636576493/McCollister%27s/Unknown-1_gz257i.png" />
                <img id="mccollisters-logo" alt="McCollister's Logo" src="https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mccollisters-auto-logistics.png" />
            </div>
        </header>
    )
}